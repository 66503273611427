import { formatCurrency } from "@/utils/utility";

const INSTALLMENT_MIN_VALUE = 5;

export const generateMaxInstallmentsOption = (maxInstallment: number) => {
  return new Array(maxInstallment).fill(null).map((_, index) => index + 1);
};

export const getInstallmentValue = (dividend: number, divisor: number) => {
  const result = dividend / divisor;

  if (result < INSTALLMENT_MIN_VALUE) return false;

  return formatCurrency(result);
};

export const getInstallmentSelectOptions = (
  totalAmount: number,
  maxInstallment: number,
) => {
  const installmentOption = generateMaxInstallmentsOption(maxInstallment);

  if (totalAmount < INSTALLMENT_MIN_VALUE)
    return [`${1}x de ${formatCurrency(totalAmount)}`];

  return installmentOption.reduce((list, installment) => {
    const installmentValue = getInstallmentValue(totalAmount, installment);
    if (!installmentValue) return list;

    list.push(`${installment}x de ${installmentValue}`);

    return list;
  }, []);
};
